import BasePlugin from '../BasePlugin'
import ShowCurrentLocationWindow from './ShowCurrentLocation.vue'

export default class ShowCurrentLocation extends BasePlugin {
  executors = []

  async execute () {
    let map = this.context.getDashboardComponents()['component_9607ca04-12e4-47a2-8524-b1c9f60909ad'][0]
    this.executors = this.context.getDashboardComponents()['component_46394754-5532-4584-a0fb-05ee36aec8f9'][0].options
    let model = {
      date: new Date(),
      executors: null
    }

    const h = this.context.$createElement
    const roleId = this.context.$store.getters['Authorization/roleId']
    const userId = this.context.$store.getters['Authorization/userId']
    let showCurrentLocationWindow = h(ShowCurrentLocationWindow, { key: Math.random(),
      props: {
        model: model, roleId: roleId, userId: userId
      } })
    this.context.$msgbox({
      title: 'Выберите параметры',
      message: h('div', { key: Math.random() }, [
        showCurrentLocationWindow
      ]),
      showCancelButton: true,
      cancelButtonText: 'Отмена',
      confirmButtonText: 'Добавить',
      beforeClose: async (action, instance, done) => {
        if (action === 'confirm') {
          if (await showCurrentLocationWindow.componentInstance.isValid()) {
            map.yMaps().geoObjects.removeAll()
            model.executors.forEach((item) => this.showExecutorPosition(item, model.date, map))
            done()
          }
        } else {
          done()
        }
      }
    }).then(action => {
    })
  }

  showError (text) {
    this.context.$message({
      type: 'error',
      dangerouslyUseHTMLString: true,
      message: text
    })
  }

  async showExecutorPosition (executor, date, map) {
    if (!date) {
      date = new Date()
    }
    if (!executor) {
      console.warn(`Не выбран исполнитель`)
      return false
    }
    let filters = [
      `attr_2545_,lt,${this.context.$moment(date).add(1, 'days').format('Y-M-DD')}`,
      `attr_2544_,eqx,${executor}`
    ].map((item, index) => {
      return `filter[${index}]=${item}`
    })

    let data = await this.context.$http.get(
      `${this.context.$config.api}/registryservice/registry/2542/data?limit=1&${filters.join('&')}`
    )

    if (data.data.count === 0) {
      console.warn(`Нет данных, executor_id = ${executor}`)
      return false
    }

    const positionField = 'attr_2546_'
    let markers = []
    try {
      markers = JSON.parse(data.data.data[0][positionField]).filter((item) => item.status === 'LOCATION_UPDATED' || item.status === 'AVAILABLE').map((item) => {
        return { lat: item.lat, lon: item.lon, time: item.dateLocation }
      })
    } catch (e) {
      console.warn(`Неккоректные данные, executor_id = ${executor}`)
      return false
    }
    if (markers.length === 0) {
      console.warn(`Нет данных в маршруте, executor_id = ${executor}`)
      return false
    }
    let lastPoint = markers[markers.length - 1]
    let caption = `${this.executors.find((item) => item.id === executor).name} - ${this.context.$moment(new Date(lastPoint.time)).format('DD MMMM Y в H:mm:ss')}`
    let point = new window.ymaps.GeoObject({
      // Описание геометрии.
      geometry: {
        type: 'Point',
        coordinates: [lastPoint.lat, lastPoint.lon]
      },
      // Свойства.
      properties: {
        // Контент метки.
        iconContent: caption
      }
    }, {
      // Опции.
      // Иконка метки будет растягиваться под размер ее содержимого.
      preset: 'islands#blackStretchyIcon',
      iconColor: '#409EFF'
    })

    map.yMaps().geoObjects.add(point)
  }
}
